<template>
  <v-container>
    <v-card class="elevation-2 mx-auto" :loading="loading" max-width="600">
      <v-card-title>Add Testimonial</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <form>
          <v-row>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-text-field
                label="Customer Name"
                v-model="form_data.customer_name"
                :error-messages="form_error.customer_name"
                outlined
                dense
                autofocus
              ></v-text-field>
            </v-col>
          </v-row>
           <v-row>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-text-field
                label="Customer Designation"
                v-model="form_data.designation"
                :error-messages="form_error.designation"
                outlined
                dense
                autofocus
              ></v-text-field>
            </v-col>
          </v-row>
           <v-row>
            <v-col cols="12" class="py-0">
              <v-textarea
                outlined
                rows="3"
                v-model="form_data.description"
                label="Description"
                hide-details=""
              ></v-textarea>
            </v-col>
          </v-row>
          <v-btn
            class="mr-4 mt-5"
            color="secondary"
            @click="addTestimonial"
            :loading="loading"
            :disabled="loading"
            >submit</v-btn
          >
        </form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "AddTestimonial",
  data() {
    return {
      form_data: {},
      form_error: {},
      loading: false,
      data: {},
    };
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    addTestimonial() {
      let _self = this;
      this.loading = true;
       this.$axios.post(`admin/testimonial/add`, _self.form_data)
        .then((response) => {
          _self.form_error = {};
          let response_data = response.data;
          if (response_data.status) {
            _self.setAlert({
              show: true,
              variant: "success",
              message: response.data.message,
              dismissCountDown: 5000,
            });
            this.user_data = response_data.user_data;
            _self.$router.push("/testimonial");
          } else {
            let errors = response_data.error;
            for (let key in errors) {
              _self.form_error[errors[key].field] = errors[key].message;
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    }
  },
};
</script>
